import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { gtmPush, ordenarEtiquetas } from '@/utils';
import {
    ReadCart,
    RemoveItemFromCart,
    UpdateItemFromCart,
    addItemToCart,
    newCart,
} from './utils/shopify';

import { showPopUp } from "lib/cartPopUp";
import { unitLimitQuantity, unitLimitTag } from './constants';
import { processUnitLimit } from './utils/utils';

export interface CARTS {
    loading: boolean;
    order: string;
    data: {
        cart: {
            id: string;
            createdAt: string;
            updatedAt: string;
            checkoutUrl: string;
            lines: {
                edges: {
                    node?: {
                        id: string;
                        quantity: number;
                        merchandise: {
                            id: string;
                        };
                    };
                }[];
            };
            attributes: {
                key?: string;
                value?: string;
            }[];
            cost: {
                totalAmount: {
                    amount: string;
                    currencyCode: string;
                };
                subtotalAmount: {
                    amount: string;
                    currencyCode: string;
                };
                checkoutChargeAmount: {
                    amount: string;
                    currencyCode: string;
                };
                totalTaxAmount: {
                    amount: string;
                    currencyCode: string;
                } | null;
                totalDutyAmount: {
                    amount: string;
                    currencyCode: string;
                } | null;
            };
        };
    };
}

const initialState: CARTS = {
    loading: true,
    order: '#',
    data: {
        cart: {
            id: '',
            createdAt: '',
            updatedAt: '',
            checkoutUrl: '',
            lines: {
                edges: [],
            },
            attributes: [],
            cost: {
                totalAmount: {
                    amount: '0.0',
                    currencyCode: 'EUR',
                },
                subtotalAmount: {
                    amount: '75.0',
                    currencyCode: 'EUR',
                },
                checkoutChargeAmount: {
                    amount: '75.0',
                    currencyCode: 'EUR',
                },
                totalTaxAmount: null,
                totalDutyAmount: null,
            },
        },
    },
};

const __loadCart = async (cartId: string) => {
    try {
        const data = await ReadCart(cartId);

        return await data.data;
    } catch (error) {
        return error;
    }
};

const __createCart = async () => {
    try {
        const data = await newCart();
        return data.data.cartCreate;
    } catch (error) {
        return error;
    }
};

const __newItem = async (cartId: string, itemId: string) => {
    try {
        const data = await addItemToCart(cartId, itemId, 1);

        return data.data.cartLinesAdd;
    } catch (error) {
        return error;
    }
};

const __updateLine = async (
    cartId: string,
    lineId: string,
    itemId: string,
    quantity: number
) => {
    try {

        const data = await UpdateItemFromCart(cartId, lineId, itemId, quantity);
        return data.data.cartLinesUpdate;
    } catch (error) {
        return error;
    }
};

const __removeLine = async (cartId: string, lineId: string) => {
    try {
        const data = await RemoveItemFromCart(cartId, lineId);
        return data.data.cartLinesRemove;
    } catch (error) {
        return error;
    }
};

export const loadCart = createAsyncThunk('cart/load', async () => {
    const cartId = sessionStorage.getItem('cart') || '';

    if (cartId !== '') {
        return await __loadCart(cartId);
    } else {
        sessionStorage.removeItem('typesElements');
    }
});

export const addToCart = createAsyncThunk(
    'cart/add',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (item: any, {dispatch, getState}) => {
        //  const giftValues = item.metafields.custom?.gift?.value === 'true';
        let cartId = sessionStorage.getItem('cart') || '';
        //const typeElementId = sessionStorage.getItem('typeElements') || '';

        if (cartId === '') {
            const data = await __createCart();
            cartId = data.cart.id;
            sessionStorage.setItem('cart', data.cart.id);
        }
        //if (typeElementId === '') {
        //   if (giftValues) {
        //      dispatch(setTipoProducto('gift'));
        //      sessionStorage.setItem('typeElements', 'gift');
        //    } else {
        //     dispatch(setTipoProducto('normal'));
        //     sessionStorage.setItem('typeElements', 'normal');
        //   }
        //}
        const etiquetas = item ? item.tags.map((item) => (item.name)) : [];
        const {cart,totalItems} = processUnitLimit(getState)
        //descomentar
        if(totalItems < unitLimitQuantity || !item.tags.some((tag) => tag.name === unitLimitTag)){
            gtmPush({ ecommerce: null });
            gtmPush({
                event: 'add_to_cart',
                ecommerce: {
                    currency: 'EUR',
                    value: item.variants[0].price,
                    items: [
                        {
                            item_id: item.product_id,
                            item_name: item.title.toUpperCase(),
                            affiliation: 'Navidul',
                            item_brand: 'Navidul',
                            item_category: ordenarEtiquetas(etiquetas)[0][0]
                                ? ordenarEtiquetas(etiquetas)[0][0]
                                : '',
                            item_category2: ordenarEtiquetas(etiquetas)[0][1]
                                ? ordenarEtiquetas(etiquetas)[0][1]
                                : '',
                            item_category3: ordenarEtiquetas(etiquetas)[0][2]
                                ? ordenarEtiquetas(etiquetas)[0][2]
                                : '',
                            price: item.variants[0].price,
                            quantity: 1,
                            discount: item?.variants[0].compare_at_price &&
                            item?.variants[0].price
                            ? item?.variants[0].compare_at_price -
                            item?.variants[0].price
                            : 0,
                        },
                    ],
                },
            });
            return await __newItem(cartId, item.variants[0].variant_id);
        }else{
            dispatch(showPopUp());
            return cart.data;
        }
    }
);

export const updateLineFromCart = createAsyncThunk(
    'cart/update',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ lineId, itemId, product, quantity }: any,{dispatch, getState}) => {
        const cartId = sessionStorage.getItem('cart') || '';

        if (cartId !== '') {
            const actualCart = await __loadCart(cartId);
            const cartAnterior = actualCart.cart.lines.edges.filter(
                (edge) => edge.node.merchandise.id === `gid://shopify/ProductVariant/${itemId}`
            );
            const {cart,totalItems} = processUnitLimit(getState);
            if(cartAnterior[0].node.quantity < quantity && product.tags.some((tag) => tag.name === unitLimitTag) && totalItems >= unitLimitQuantity){
                dispatch(showPopUp())
                return cart.data
            }
            const carts = await __updateLine(cartId, lineId, itemId, quantity);
            const cartNuevo = carts.cart.lines.edges.filter(
                (edge) => edge.node.merchandise.id === `gid://shopify/ProductVariant/${itemId}`
            );

            const etiquetas = product ? product.tags.map((item) => (item.name)) : [];
            gtmPush({ ecommerce: null });
            const absQuantity = Math.abs(
                cartAnterior[0].node.quantity - cartNuevo[0].node.quantity
            );
            gtmPush({
                event:
                    cartAnterior[0].node.quantity < cartNuevo[0].node.quantity
                        ? 'add_to_cart'
                        : 'remove_from_cart',
                ecommerce: {
                    currency: 'EUR',
                    value: (product.variants[0].price * absQuantity).toFixed(2),
                    items: [
                        {
                            item_id: product.product_id,
                            item_name: product.title.toUpperCase(),
                            affiliation: 'Navidul',
                            item_brand: 'Navidul',
                            item_category: ordenarEtiquetas(etiquetas)[0][0]
                                ? ordenarEtiquetas(etiquetas)[0][0]
                                : '',
                            item_category2: ordenarEtiquetas(etiquetas)[0][1]
                                ? ordenarEtiquetas(etiquetas)[0][1]
                                : '',
                            item_category3: ordenarEtiquetas(etiquetas)[0][2]
                                ? ordenarEtiquetas(etiquetas)[0][2]
                                : '',
                            price: product.variants[0].price,
                            quantity: absQuantity,
                            discount: product?.variants[0].compare_at_price &&
                            product?.variants[0].price
                            ? product?.variants[0].compare_at_price -
                            product?.variants[0].price
                            : 0,
                        },
                    ],
                },
            });
            return await carts;
        }
    }
);

export const removeLineFromCart = createAsyncThunk(
    'cart/remove',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ lineId, product }: any) => {

        const cartId = sessionStorage.getItem('cart') || '';
        if (cartId !== '') {
            const cart = await __loadCart(cartId);
            const item = cart.cart.lines.edges.filter(
                (edge) => edge.node.id === lineId
            );
            const etiquetas = product ? product.tags.map((item) => (item.name)) : [];
            gtmPush({ ecommerce: null });
            gtmPush({
                event: 'remove_from_cart',
                currency: 'EUR',
                value: (product.variants[0].price*item[0].node.quantity).toFixed(2),
                ecommerce: {
                    items: [
                        {
                            item_id: product.product_id,
                            item_name: product.title.toUpperCase(),
                            affiliation: 'Navidul',
                            item_brand: 'Navidul',
                            item_category: ordenarEtiquetas(etiquetas)[0][0]
                                ? ordenarEtiquetas(etiquetas)[0][0]
                                : '',
                            item_category2: ordenarEtiquetas(etiquetas)[0][1]
                                ? ordenarEtiquetas(etiquetas)[0][1]
                                : '',
                            item_category3: ordenarEtiquetas(etiquetas)[0][2]
                                ? ordenarEtiquetas(etiquetas)[0][2]
                                : '',
                            price: product.variants[0].price,
                            quantity: item[0].node.quantity,
                            discount:
                                product.variants[0].compare_at_price &&
                                    product.variants[0].price
                                    ? product.variants[0].compare_at_price -
                                    product.variants[0].price
                                    : 0,
                        },
                    ],
                },
            });
            return await __removeLine(cartId, lineId);
        }
    }
);

export const carts = createSlice({
    name: 'carts',
    initialState,
    extraReducers: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [loadCart.pending]: (state: any) => {
            state.loading = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [loadCart.fulfilled]: (state: any, action: any) => {
            state.loading = false;
            state.data = action.payload ? action.payload : state.data;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [loadCart.rejected]: (state: any, action: any) => {
            state.loading = false;
            state.error = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [addToCart.pending]: (state: any) => {
            state.loading = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [addToCart.fulfilled]: (state: any, action: any) => {
            state.loading = false;
            state.data = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [addToCart.rejected]: (state: any, action: any) => {
            state.loading = false;
            state.error = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [updateLineFromCart.pending]: (state: any) => {
            state.loading = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [updateLineFromCart.fulfilled]: (state: any, action: any) => {
            state.loading = false;
            state.data = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [updateLineFromCart.rejected]: (state: any, action: any) => {
            state.loading = false;
            state.error = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [removeLineFromCart.pending]: (state: any) => {
            state.loading = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [removeLineFromCart.fulfilled]: (state: any, action: any) => {
            state.loading = false;
            state.data = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [removeLineFromCart.rejected]: (state: any, action: any) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
    reducers: {
        clearCart: () => {
            sessionStorage.removeItem('cart');
            //state = initialState;
        },
    },
});

export const { clearCart } = carts.actions;

export default carts.reducer;
