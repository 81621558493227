import { createSlice } from "@reduxjs/toolkit";

interface CARTPOPUP  {
    visible: boolean;
}
const initialState: CARTPOPUP = {
    visible: false,
};

export const cartPopUp = createSlice({
    name: "cartPopUp",
    initialState,
    reducers: {
        showPopUp: (state) => {
            state.visible = true;
        }, 
        closePopUp: (state) => {
            state.visible = false;
        }
    },
});

export const { showPopUp, closePopUp } = cartPopUp.actions;

export default cartPopUp.reducer;
