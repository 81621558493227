import { gql } from '@apollo/client';

export const queryUnitLimit = () => gql`
query getProducts($first: Int, $query: String) {
    products(first: $first, query: $query) {
      edges {
        node {
          variants(first: 1){
              edges{
                  node{
                      id
                  }
              }
          }
        }
      }
    }
  }`
