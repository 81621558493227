import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getUnitLimits
} from './utils/shopify';
import { unitLimitQuery } from 'lib/constants';


export const fetchUniLimitId = createAsyncThunk('shopify/unitLimitId', async () => {
  try {
    const data = await getUnitLimits(unitLimitQuery);
    const productIds = await data.data;
    return productIds.products?.edges.map((item) => (
      item.node?.variants?.edges[0].node?.id
    ));
  } catch (error) {
    return error;
  }
});

const initialState = {
  loading: true,
  data: {
    productId: []
  }
}

export const unitLimitIds = createSlice({
  name: 'unitLimitIds',
  initialState,
  extraReducers: {
    [fetchUniLimitId.pending]: (state) => {
      state.loading = true;
    },
    [fetchUniLimitId.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchUniLimitId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  reducers: {},
});

export default unitLimitIds.reducer;
