import { configureStore } from '@reduxjs/toolkit';
import orders from '@/lib/orders';
import carts from '@/lib/carts';
import unitLimitIds from '@/lib/unitLimitId'
import shopifydiscount from '@/lib/discount';
import typeElements from './typeElements';
import cartPopUp from '@/lib/cartPopUp';

export const store = configureStore({
  reducer: {
   // faqs: faqs,
   // advantages: advantages,
  //  shopifyprod: shopifyprod,
    orders: orders,
    carts: carts,
    unitLimitIds: unitLimitIds,
    shopifydiscount: shopifydiscount,
    typeElements: typeElements,
    cartPopUp: cartPopUp,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: true,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
